<template>
  <div class="flex flex-col lg:flex-row lg:space-x-4">
    <Sidebar
      :sidebarHeader="'Nurses'"
      :treatmentCategories="nurseCategories"
      :setTreatmentOptions="nurseOptions"
      @updateTreatmentOptions="nurseOptions = $event"
    />
    <vs-card class="w-full lg:w-9/12">
      <h5 class="text-green-dark font-bold my-3 mx-2">
          Number of Days Since The Last Appointment
      </h5>
      <vs-tabs v-model="active">
        <vs-tab label="By Nurse">
          <OvertimeDetails
            :selected="selected"
            :chartDataProps="chartData"
            type="Nurse"
            @updateChartData="chartData = $event"
            @updateFilters="selected = $event"
          />
        </vs-tab>
      </vs-tabs>
      
      <vs-table
        id="nursesInsightsTable"
        v-if="filteredData && filteredData.length"
        max-items="10"
        pagination
        :data="filteredData"
        noDataText="No Data Available"
        :hoverFlat="true"
        :key="'main-' + tableKey"
        class="customtable w-full"
      >

        <template slot="thead">
          <vs-th>Nurse</vs-th>
          <!-- <vs-th>{{selectedParams.toUpperCase()}}</vs-th> -->
          <vs-th sort-key="dataset">Number of days</vs-th>
          <!-- <vs-th v-if="selectedParams !== 'clinic'">ACTIVE</vs-th>
          <vs-th v-else>LAST APPOINTMENT DATE</vs-th> -->
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="`cr-${indextr}`" v-for="(tr, indextr) in data">

            <vs-td data-label="Nurse" :data="tr.label">
              {{ getHeaderValue(tr.label) }}
            </vs-td>
            
            <vs-td data-label="Number of days" :data="tr.label">
              {{ getTotal(tr.label) }}
            </vs-td>
          
          </vs-tr>
        </template>

      </vs-table>
    </vs-card>
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import OvertimeDetails from "../../components/insights/nurses/OvertimeDetails.vue";
import TableInsights from "../../components/insights/TableInsights.vue";
import TabInsights from "../../components/insights/TabsInsights.vue";
import Sidebar from "./Sidebar.vue";
import utils from "@/assets/utils";
import helper from "./helper";

export default {
  components: {
    OvertimeDetails,
    TableInsights,
    TabInsights,
    Sidebar,
  },
  data() {
    return {
      active: 0,
      chartData: {},
      filteredData: [],
      customRows: ["Category", "dataset"],
      nurseOptions: [
        {
          text: "Number of Days Since The Last Appointment",
          urlParams: "",
          isActive: true,
          subMenu: [],
          url: null,
        },
      ],
      nurseCategories: [
        {
          text: "Number of Days Since The Last Appointment",
          urlParas: "",
          isActive: true,
          tabName: "",
          fillerName: "",
          toxinName: "",
        },
      ],
      statusType: ["month", "week", "year", "day", "clinic"],
      selected: {},
      orgId: "",
      urlType: "",
      tableKey: "",
    };
  },
  computed: {
    ...mapState("analytics", ["FILTERS_SELECTED"]),
  },
  methods: {
    ...mapActions("analytics", [
      "fetchLastAppointments",
      "fetchAnalyticsFilters",
    ]),
    getLastAppointments() {
      this.$vs.loading();
      this.selected.is_nurse = true;
      let payload = helper.generateAnalyticsAPIParameters(this.selected);

      if (!["SuperAdmin", "Supplier"].includes(this.urlType)) {
        payload = {
          ...payload,
          org: [this.orgId],
        };
      }
      this.fetchLastAppointments(payload)
        .then((res) => {
          this.chartData = res.data.chartData;
          const { labels, datasets } = res.data.chartData;
          const clinics = datasets.map((e) => e.label);
          this.filteredData = labels.map((label, i) => {
            let data = { label };
            clinics.map((_, index) => {
              data = {
                ...data,
                [datasets[index].label]: datasets[index].data[i],
              };
            });
            return data;
          });
          this.$router.replace({
            query: {
              filter: this.selectedParams,
              ...this.selected,
            },
          });
          this.$vs.loading.close();
        })
        .catch((err) => {
          console.error(err.message);
          this.$vs.loading.close();
        });
    },
    getHeaderValue(value) {
      if (this.selectedParams === "day") {
        return moment(value, "DD-MM-YYYY").format("LL");
      }
      if (this.selectedParams === "month") {
        let splitData = value.split(" ");
        return `${moment()
          .month(parseInt(splitData[0]) - 1)
          .format("MMMM")} ${splitData[1]}`;
      }
      return value;
    },
    getTotal(index) {
      let dataIndex = this.chartData.labels.findIndex((e) => e === index);
      let totalAmount = this.chartData.datasets.reduce((total, curr) => {
        total += curr.data[dataIndex];
        return total;
      }, 0);
      return totalAmount;
    },
  },
  watch: {
    active: {
      handler(newVal) {
        this.selectedParams = this.statusType[newVal];
        this.filteredData = [];
        this.getLastAppointments();
      },
    },
    selected: {
      handler() {
        this.getLastAppointments();
      },
      deep: true,
    },
    filteredData: {
      handler() {
        const randNumber = Math.random().toString(16).slice(2);
        this.tableKey = randNumber;
      },
      deep: true,
    },
  },
  created() {
    this.selected = _.cloneDeep(this.FILTERS_SELECTED);
    if (
      localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      )
    ) {
      this.orgId = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
    }
    let userType = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if (userType && userType.userType === "superAdmin") {
      this.urlType = "SuperAdmin";
    } else if (
      userType &&
      userType.organizations[0].role.toUpperCase() === "supplier".toUpperCase()
    ) {
      this.urlType = "Supplier";
    } else {
      this.urlType = "OrgOwner";
    }
    this.getLastAppointments();
  },
  destroyed() {
    this.hideMarkerWidget();
  }
};
</script>

<style>
  .customtable .vs-table--thead th {
      background: #f3faf8;
  }
  /* Responsive <table> */
  @media screen and (max-width: 600px) {
    .customtable .vs-table--content .vs-con-table .vs-con-tbody {
        border: none;
    }
    .customtable .vs-table--tbody-table {
        min-width: unset;
    }
    .customtable .vs-table--header {
      flex-direction: column;
    }
    .customtable .vs-table--thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      display: none !important;
    }
    .customtable .vs-table--tr {
      display: block;
      margin-bottom: .625em;
    }
    .customtable .vs-table--td {
      border-bottom: .05rem solid #ddd;
      display: block;
      font-size: 1rem;
      text-align: right;
    }
    .customtable .vs-table--td:first-child{
      min-width: 230px;
    }
    .customtable .vs-table--td::before {
      content: attr(data-label);
      float: left;
      font-weight: 600;
    }
    .customtable .vs-table--td:last-child {
      border-bottom: 0;
    }
  }
</style>