<template>
    <vs-table
    ref="table"
    :data="data"
    noDataText="No Data Available"
    hoverFlat=true
    >
        <template slot="thead">
            <vs-th v-for="(th, indexth) in label" :key="indexth">{{th}}</vs-th>
        </template>

        <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                <vs-td  v-for="(value, name) in data[indextr]" :key="name">
                {{value}}
                </vs-td>
            </vs-tr>
        </template>
    </vs-table>
</template>

<script>

export default {
  props: {
    data: Array,
    label: Array
  },
  data() {
    return {};
  },
  
};
</script>

<style>
  .vs-con-table .vs-con-tbody{
  border: 0;
}
.vs-table--thead tr{
  background: #F3FAF8;
}
.vs-table--content{
  border-radius: 0.5rem;
}
</style>

