<template>
  <!-- Nurses -->
  <div>
    <vs-row vs-w="12">
      <GlobalFilters 
        :categories="categories"
        :subCategories="subCategories"
        :selectedCategory="selectedCategory"
        :selectedSubCategory="selectedSubCategory"
        @updateSelectedCategory="selectedCategory = $event"
        @updateSelectedSubCategory="selectedSubCategory = $event"
        :selected="selected"
        :page="'nurses'"
        @updateFilters="updateFilters"
        :urlType="urlType"
      />
      <vs-col vs-w="12">
        <vs-row
          v-if="allTotalCount"
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="right"
          vs-w="12"
        >Total count of days: {{ Number.parseFloat(allTotalCount).toFixed(0) }}
        </vs-row>
        <BarChart
          :chartData="chartData"
          :chartOptions="chartOptions"
        />
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BarChart from '../charts/BarChart.vue'
import GlobalFilters from "../common/GlobalFilters"
import {
  filterCategories
} from "../common/GlobalFilters/filterConstant.js"

export default {
  components: {
    BarChart,
    GlobalFilters
  },
  props: {
    tabs: Array,
    type: {
      type: String,
      default: "month",
    },
    chartDataProps: {
      type: Object,
    },
    selected: {
      type: Object,
    },
  },
  computed: {
    allTotalCount(){      
      let count = 0;
      const chartDataSets = this.chartData.datasets ? this.chartData.datasets[0] : null
      if(chartDataSets && chartDataSets.data) {
        chartDataSets.data.forEach(val => {
            count += val;
        });
      }

      return count
    }
  },
  data() {
    return {
      categories: [
        filterCategories.ORGANIZATIONS,
        filterCategories.CLINICS
      ],
      selectedCategory: {},
      subCategories: [],
      selectedSubCategory: {},
      // Nurses
      chartData: {},
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
            display: false,
            labels: {
              boxWidth: 12,
            },
          },
          title: {
            display: true,
            text: "",
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: "NUMBER OF DAYS",
            },
            min: 0,
          },
          x: {
            title: {
              display: true,
              text: "Months",
            },
            grid: {
              display: false,
            },
            ticks: {
              minRotation: 90,
              maxRotation: 90,
            },
          },
        },
      },
      urlType: "",
      orgId: "",
    };
  },
  methods: {
    ...mapActions("analytics", ["fetchAnalyticsFilters"]),
    updateFilters(newVal) {
      this.$emit("updateFilters", newVal);
    },
    getFilters() {
      let payload = {}

      if (!["SuperAdmin", "Supplier"].includes(this.urlType)) payload = {
        org_id: [this.orgId],
      }

      this.$vs.loading();
      this.fetchAnalyticsFilters(payload)
        .then(() => this.$vs.loading.close())
        .catch((err) => {
          console.error("##ERROR: ", err.message);
          this.$vs.loading.close();
        });
    },
  },
  watch: {
    chartDataProps: {
      handler(newVal) {
        this.chartData = newVal;
      },
      immediate: true,
    },
    chartData: {
      handler(newVal) {
        this.$emit("updateChartData", newVal);
      },
      deep: true,
    },
    type: {
      handler(newVal) {
        this.chartOptions.scales.x.title.text = newVal.toUpperCase();
      },
      immediate: true,
    },
  },
  async created() {
    if(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)){
      this.orgId = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)
    }
    let userType = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if(userType && userType.userType === "superAdmin"){
      this.urlType = "SuperAdmin";
    }else if(userType && userType.organizations[0].role.toUpperCase() === 
    "supplier".toUpperCase()){
      this.urlType = "Supplier";
    }else{
      this.urlType = "OrgOwner";
    }
    
    this.getFilters();
  },
};
</script>
